import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_ENTRY_FIELDS_HISTORY_REQUEST,
  FETCH_ENTRY_FIELDS_HISTORY_RESPONSE,
  FETCH_ENTRY_FIELDS_HISTORY_FAILURE,
  FETCH_ENTRY_FIELD_HISTORY_REQUEST,
  FETCH_ENTRY_FIELD_HISTORY_RESPONSE,
  FETCH_ENTRY_FIELD_HISTORY_FAILURE,
  /*  POST_ENTRY_FIELD_HISTORY_FAILURE,
  POST_ENTRY_FIELD_HISTORY_REQUEST,
  POST_ENTRY_FIELD_HISTORY_RESPONSE,*/
  EntryFieldHistoryActionTypes, EntryFieldHistoryDataModel, EntryFieldHistory,
} from '../types/EntryFieldHistory';

import { RootState } from '../store/configureStore';

import {
  archiveEntryFieldHistory, /*archiveEntryFieldHistory,*/
  getEntryFieldHistory,
  getEntryFieldHistoryByShortcode,
  getEntryFieldsHistory, unarchiveEntryFieldHistory, /*, postEntryFieldHistory, putEntryFieldHistory */
} from '../api/EntryFieldHistory';
import { DataTableParamsModel } from '../types/Common';

export function fetchEntryFieldsHistoryRequest(): EntryFieldHistoryActionTypes {
  return {
    type: FETCH_ENTRY_FIELDS_HISTORY_REQUEST,
    payload: null,
  };
}

export function fetchEntryFieldsHistoryResponse(
  entryFieldsHistory: EntryFieldHistoryDataModel,
): EntryFieldHistoryActionTypes {
  return {
    type: FETCH_ENTRY_FIELDS_HISTORY_RESPONSE,
    payload: entryFieldsHistory,
  };
}

export function fetchEntryFieldsHistoryFailure(): EntryFieldHistoryActionTypes {
  return {
    type: FETCH_ENTRY_FIELDS_HISTORY_FAILURE,
    payload: null,
  };
}

export function fetchEntryFieldHistoryRequest(): EntryFieldHistoryActionTypes {
  return {
    type: FETCH_ENTRY_FIELD_HISTORY_REQUEST,
    payload: null,
  };
}

export function fetchEntryFieldHistoryResponse(
  entryFieldsHistory: EntryFieldHistory,
): EntryFieldHistoryActionTypes {
  return {
    type: FETCH_ENTRY_FIELD_HISTORY_RESPONSE,
    payload: entryFieldsHistory,
  };
}

export function fetchEntryFieldHistoryFailure(): EntryFieldHistoryActionTypes {
  return {
    type: FETCH_ENTRY_FIELD_HISTORY_FAILURE,
    payload: null,
  };
}
/*

export function postEntryFieldHistoryRequest(): EntryFieldHistoryActionTypes {
  return {
    type: POST_ENTRY_FIELD_HISTORY_REQUEST,
    payload: null,
  };
}

export function postEntryFieldHistoryResponse(): EntryFieldHistoryActionTypes {
  return {
    type: POST_ENTRY_FIELD_HISTORY_RESPONSE,
    payload: null,
  };
}

export function postEntryFieldHistoryFailure(error: string, validationErrors: any): EntryFieldHistoryActionTypes {
  return {
    type: POST_ENTRY_FIELD_HISTORY_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}*/

export const fetchEntryFieldHistory = (id: string, visibilityPublic: boolean = false):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchEntryFieldsHistoryRequest());
    const asyncResp: any = await getEntryFieldHistory(id, visibilityPublic);
    if (asyncResp?.success) {
      await dispatch(fetchEntryFieldsHistoryResponse(asyncResp));
    } else {
      await dispatch(fetchEntryFieldsHistoryFailure());
    }
  };

export const fetchEntryFieldHistoryByShortcodes = (groupShortcode: string, clientShortcode: string, id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchEntryFieldsHistoryRequest());
    const asyncResp: any = await getEntryFieldHistoryByShortcode(groupShortcode, clientShortcode, id);
    if (asyncResp?.success) {
      await dispatch(fetchEntryFieldsHistoryResponse(asyncResp));
    } else {
      await dispatch(fetchEntryFieldsHistoryFailure());
    }
  };

export const fetchEntryFieldsHistory = (datatableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchEntryFieldsHistoryRequest());
    const asyncResp: any = await getEntryFieldsHistory(datatableParams);
    if (asyncResp?.success) {
      await dispatch(fetchEntryFieldsHistoryResponse(asyncResp));
    } else {
      await dispatch(fetchEntryFieldsHistoryFailure());
    }
  };

export const storeEntryFieldHistory = async (id: string) => {
  try {
    await archiveEntryFieldHistory(id);
  } catch (error) {
    throw error;
  }
};

export const activateEntryFieldHistory = async (id: string) => {
  try {
    await unarchiveEntryFieldHistory(id);
  } catch (error) {
    throw error;
  }
};

/*
export const createEntryFieldHistory = (
  groupId: string, clientId: string, title: string, description: string, active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postEntryFieldHistoryRequest());
    const asyncResp: any = await postEntryFieldHistory(groupId, clientId, title, description, active);
    if (asyncResp?.success) {
      await dispatch(postEntryFieldHistoryResponse());
      await dispatch(fetchEntryFieldsHistory());
    } else {
      await dispatch(postEntryFieldHistoryFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };
*/

/*
export const updateEntryFieldHistory = (
  id: string,
  groupId: string,
  clientId: string,
  title: string,
  description: string,
  active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postEntryFieldHistoryRequest());
    const asyncResp: any = await putEntryFieldHistory(id, groupId, clientId, title, description, active);
    if (asyncResp?.success) {
      await dispatch(postEntryFieldHistoryResponse());
      await dispatch(fetchEntryFieldsHistory());
    } else {
      await dispatch(postEntryFieldHistoryFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };
*/


