import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_ENTRIES_HISTORY_REQUEST,
  FETCH_ENTRIES_HISTORY_RESPONSE,
  FETCH_ENTRIES_HISTORY_FAILURE,
  FETCH_ENTRY_HISTORY_REQUEST,
  FETCH_ENTRY_HISTORY_RESPONSE,
  FETCH_ENTRY_HISTORY_FAILURE,
  FETCH_ENTRY_CHILD_LOCATION_HISTORY_REQUEST,
  FETCH_ENTRY_CHILD_LOCATION_HISTORY_RESPONSE,
  FETCH_ENTRY_CHILD_LOCATION_HISTORY_FAILURE,
  FETCH_ENTRY_PARENT_LOCATION_HISTORY_REQUEST,
  FETCH_ENTRY_PARENT_LOCATION_HISTORY_RESPONSE,
  FETCH_ENTRY_PARENT_LOCATION_HISTORY_FAILURE,
  /*  POST_ENTRY_HISTORY_FAILURE,
  POST_ENTRY_HISTORY_REQUEST,
  POST_ENTRY_HISTORY_RESPONSE,*/
  EntryHistoryActionTypes,
  EntryHistoryDataModel,
  EntryHistory,
  FETCH_ENTRY_TIMELINE_RESPONSE,
  FETCH_ENTRY_TIMELINE_REQUEST, FETCH_ENTRY_TIMELINE_FAILURE, TimelineDataModel, ChildLocationHistoryDataModel, ParentLocationHistoryDataModel,
} from '../types/EntryHistory';

import { RootState } from '../store/configureStore';

import { /*archiveEntryHistory,*/
  getEntryHistory,
  getEntriesHistory,
  getEntryHistoryByShortcodes,
  getEntryTimeline,
  getEntryTimelineByShortcodes,
  getChildLocationHistory,
  getParentLocationHistory, archiveEntryHistory, unarchiveEntryHistory, /*, postEntryHistory, putEntryHistory */
} from '../api/EntryHistory';
import { DataTableParamsModel } from '../types/Common';

export function fetchEntriesHistoryRequest(): EntryHistoryActionTypes {
  return {
    type: FETCH_ENTRIES_HISTORY_REQUEST,
    payload: null,
  };
}

export function fetchEntriesHistoryResponse(
  entriesHistory: EntryHistoryDataModel,
): EntryHistoryActionTypes {
  return {
    type: FETCH_ENTRIES_HISTORY_RESPONSE,
    payload: entriesHistory,
  };
}

//Timeline
export function fetchEntryTimelineRequest(): EntryHistoryActionTypes {
  return {
    type: FETCH_ENTRY_TIMELINE_REQUEST,
    payload: null,
  };
}

export function fetchEntryTimelineResponse(
  timeline: TimelineDataModel,
): EntryHistoryActionTypes {
  return {
    type: FETCH_ENTRY_TIMELINE_RESPONSE,
    payload: timeline,
  };
}

export function fetchEntryTimelineFailure(): EntryHistoryActionTypes {
  return {
    type: FETCH_ENTRY_TIMELINE_FAILURE,
    payload: null,
  };
}

export function fetchEntriesHistoryFailure(): EntryHistoryActionTypes {
  return {
    type: FETCH_ENTRIES_HISTORY_FAILURE,
    payload: null,
  };
}

export function fetchEntryHistoryRequest(): EntryHistoryActionTypes {
  return {
    type: FETCH_ENTRY_HISTORY_REQUEST,
    payload: null,
  };
}

export function fetchEntryHistoryResponse(
  entriesHistory: EntryHistory,
): EntryHistoryActionTypes {
  return {
    type: FETCH_ENTRY_HISTORY_RESPONSE,
    payload: entriesHistory,
  };
}

export function fetchEntryHistoryFailure(): EntryHistoryActionTypes {
  return {
    type: FETCH_ENTRY_HISTORY_FAILURE,
    payload: null,
  };
}

export function fetchEntryChildLocationHistoryRequest(): EntryHistoryActionTypes {
  return {
    type: FETCH_ENTRY_CHILD_LOCATION_HISTORY_REQUEST,
    payload: null,
  };
}

export function fetchEntryChildLocationHistoryResponse(
  childLocationHistory: ChildLocationHistoryDataModel,
): EntryHistoryActionTypes {
  return {
    type: FETCH_ENTRY_CHILD_LOCATION_HISTORY_RESPONSE,
    payload: childLocationHistory,
  };
}

export function fetchEntryChildLocationHistoryFailure(): EntryHistoryActionTypes {
  return {
    type: FETCH_ENTRY_CHILD_LOCATION_HISTORY_FAILURE,
    payload: null,
  };
}

export function fetchEntryParentLocationHistoryRequest(): EntryHistoryActionTypes {
  return {
    type: FETCH_ENTRY_PARENT_LOCATION_HISTORY_REQUEST,
    payload: null,
  };
}

export function fetchEntryParentLocationHistoryResponse(
  parentLocationHistory: ParentLocationHistoryDataModel,
): EntryHistoryActionTypes {
  return {
    type: FETCH_ENTRY_PARENT_LOCATION_HISTORY_RESPONSE,
    payload: parentLocationHistory,
  };
}

export function fetchEntryParentLocationHistoryFailure(): EntryHistoryActionTypes {
  return {
    type: FETCH_ENTRY_PARENT_LOCATION_HISTORY_FAILURE,
    payload: null,
  };
}
/*

export function postEntryHistoryRequest(): EntryHistoryActionTypes {
  return {
    type: POST_ENTRY_HISTORY_REQUEST,
    payload: null,
  };
}

export function postEntryHistoryResponse(): EntryHistoryActionTypes {
  return {
    type: POST_ENTRY_HISTORY_RESPONSE,
    payload: null,
  };
}

export function postEntryHistoryFailure(error: string, validationErrors: any): EntryHistoryActionTypes {
  return {
    type: POST_ENTRY_HISTORY_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}*/

export const fetchEntryHistory = (id: string, visibilityPublic: boolean = false):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchEntriesHistoryRequest());
    const asyncResp: any = await getEntryHistory(id, visibilityPublic);
    if (asyncResp?.success) {
      await dispatch(fetchEntriesHistoryResponse(asyncResp));
    } else {
      await dispatch(fetchEntriesHistoryFailure());
    }
  };

export const fetchEntryTimeline = (id: string, visibilityPublic: boolean = false):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchEntryTimelineRequest());
    const asyncResp: any = await getEntryTimeline(id, visibilityPublic);
    if (asyncResp?.success) {
      await dispatch(fetchEntryTimelineResponse(asyncResp));
    } else {
      await dispatch(fetchEntryTimelineFailure());
    }
  };

export const fetchChildLocationHistory = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchEntryChildLocationHistoryRequest());
    const asyncResp: any = await getChildLocationHistory(id);
    if (asyncResp?.success) {
      await dispatch(fetchEntryChildLocationHistoryResponse(asyncResp));
    } else {
      await dispatch(fetchEntryChildLocationHistoryFailure());
    }
  };

export const fetchParentLocationHistory = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchEntryParentLocationHistoryRequest());
    const asyncResp: any = await getParentLocationHistory(id);
    if (asyncResp?.success) {
      await dispatch(fetchEntryParentLocationHistoryResponse(asyncResp));
    } else {
      await dispatch(fetchEntryParentLocationHistoryFailure());
    }
  };


export const fetchEntryTimelineByShortcodes = (groupShortcode: string, clientShortcode: string, id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchEntryTimelineRequest());
    const asyncResp: any = await getEntryTimelineByShortcodes(groupShortcode, clientShortcode, id);
    if (asyncResp?.success) {
      await dispatch(fetchEntryTimelineResponse(asyncResp));
    } else {
      await dispatch(fetchEntryTimelineFailure());
    }
  };

export const fetchEntryHistoryByShortcodes = (groupShortcode: string, clientShortcode: string, id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchEntriesHistoryRequest());
    const asyncResp: any = await getEntryHistoryByShortcodes(groupShortcode, clientShortcode, id);
    if (asyncResp?.success) {
      await dispatch(fetchEntriesHistoryResponse(asyncResp));
    } else {
      await dispatch(fetchEntriesHistoryFailure());
    }
  };

export const fetchEntriesHistory = (datatableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchEntriesHistoryRequest());
    const asyncResp: any = await getEntriesHistory(datatableParams);
    if (asyncResp?.success) {
      await dispatch(fetchEntriesHistoryResponse(asyncResp));
    } else {
      await dispatch(fetchEntriesHistoryFailure());
    }
  };

export const storeEntryHistory = async (id: string) => {
  try {
    await archiveEntryHistory(id);
  } catch (error) {
    throw error;
  }
};

export const activateEntryHistory = async (id: string) => {
  try {
    await unarchiveEntryHistory(id);
  } catch (error) {
    throw error;
  }
};

/*
export const createEntryHistory = (
  groupId: string, clientId: string, title: string, description: string, active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postEntryHistoryRequest());
    const asyncResp: any = await postEntryHistory(groupId, clientId, title, description, active);
    if (asyncResp?.success) {
      await dispatch(postEntryHistoryResponse());
      await dispatch(fetchEntriesHistory());
    } else {
      await dispatch(postEntryHistoryFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };
*/

/*
export const updateEntryHistory = (
  id: string,
  groupId: string,
  clientId: string,
  title: string,
  description: string,
  active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postEntryHistoryRequest());
    const asyncResp: any = await putEntryHistory(id, groupId, clientId, title, description, active);
    if (asyncResp?.success) {
      await dispatch(postEntryHistoryResponse());
      await dispatch(fetchEntriesHistory());
    } else {
      await dispatch(postEntryHistoryFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };
*/


