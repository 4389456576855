import { ApiDataModel, FormError } from './Common';

export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST';
export const FETCH_USERS_RESPONSE = 'FETCH_USERS_RESPONSE';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';

export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST';
export const FETCH_USER_RESPONSE = 'FETCH_USER_RESPONSE';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';

export const POST_USER_REQUEST = 'POST_USER_REQUEST';
export const POST_USER_RESPONSE = 'POST_USER_RESPONSE';
export const POST_USER_FAILURE = 'POST_USER_FAILURE';

export interface User {
  id: string;
  roleId: string;
  roleTitle: string;
  roleAccessLevel: number;
  clients: { clientId: string, clientTitle: string, clientGroupId: string }[];
  groups: { groupId: string, groupTitle: string }[];
  email: string;
  password: string;
  username: string;
  firstName: string;
  lastName: string;
  location: string;
  occupation: string;
  bio: string;
  avatar: string;
  companyName: string;
  active: boolean;
  verified: number;
  created: Date;
  updated: Date;
}

export interface UserDataModel extends Omit<ApiDataModel, 'data'> {
  data: User[];
}

export interface UserState {
  users: UserDataModel | null,
  activeUser: User | null,
  isFetchingUsers: boolean;
  fetchingUsersFailed: boolean;
  postingUserFailed?: boolean;
  isPostingUser: boolean;
  postingUserValidationErrors: FormError[] | null;
  postingUserError: string | null;
}

interface FetchUsersRequestAction {
  type: typeof FETCH_USERS_REQUEST;
  payload: null;
}

interface FetchUsersResponseAction {
  type: typeof FETCH_USERS_RESPONSE;
  payload: UserDataModel;
}

interface FetchUsersFailureAction {
  type: typeof FETCH_USERS_FAILURE;
  payload: null;
}

interface FetchUserRequestAction {
  type: typeof FETCH_USER_REQUEST;
  payload: null;
}

interface FetchUserResponseAction {
  type: typeof FETCH_USER_RESPONSE;
  payload: User;
}

interface FetchUserFailureAction {
  type: typeof FETCH_USER_FAILURE;
  payload: null;
}

interface PostUserRequestAction {
  type: typeof POST_USER_REQUEST;
  payload: null;
}

interface PostUserResponseAction {
  type: typeof POST_USER_RESPONSE;
  payload: null;
}

interface PostUserFailureAction {
  type: typeof POST_USER_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}


export type UserActionTypes =
    | FetchUsersRequestAction
    | FetchUsersResponseAction
    | FetchUsersFailureAction
    | PostUserRequestAction
    | PostUserResponseAction
    | PostUserFailureAction
    | FetchUserRequestAction
    | FetchUserResponseAction
    | FetchUserFailureAction;
