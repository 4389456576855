import React, { ReactNode, useEffect, useState } from 'react';
import './DataTable.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Eye, FileEarmarkPlus, FolderPlus, Pencil, Trash } from 'react-bootstrap-icons';
import DropDown from '../DropDown';
import SearchBar from '../SearchBar';
import Button from '../Button';
import { useDispatch } from 'react-redux';
import { range } from 'lodash';
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';
import { MdHorizontalRule } from 'react-icons/md';
import { DataTableParamsModel, Paging } from '../../types/Common';
import ButtonToggler from '../ButtonToggler';
import assetGallery from '../AssetGallery';
import AssetGallery from '../AssetGallery';
import Loading from '../Loading';
import { FaAngleDoubleLeft } from 'react-icons/fa';
import { useHistory, useLocation } from 'react-router-dom';
import { exportToCSV, generateQRCodeValue } from '../../helpers/functions';
import qs from 'qs'; // A library to handle query string parsing and stringifying
import ImageButton from '../ImageButton';


type Column = {
  label: string;
  format?: (value: any, row: any) => string | ReactNode;
  key: string;
  hidden?: boolean;
  mobileFriendly?: boolean;
  tabletFriendly?: boolean;
  icon?: ReactNode;
};

type Action = {
  label?: string;
  icon: 'edit' | 'bin' | 'eye' | 'convert' | string;
  onClick?: (row: any) => void;
  isVisible?: (row: any) => boolean | undefined;
};

type Config = {
  columns: Column[];
  actions: Action[];
  hideControls?: boolean;
  hideOverheadControls?: boolean;
  hideButton?: boolean;
  hasExtraAction?: boolean;
  pageSizes?: number[];
};

type Props = {
  //new params
  fetchFunction?:(searchParam?: DataTableParamsModel) => void ;
  paging?: Paging;
  //pass 'all' as key in baseSearch if you want to search all columns
  baseSearch?: Record<string, any>;
  fetchParams?: {};
  isTable?: boolean;
  lineItemTemplate?: (row: any) => React.ReactNode;
  loading?: boolean;
  error?: boolean;
  data: any[] | undefined;
  config: Config;
  title?: string;
  ctaTitle?: string;
  onCreate?: () => void;
  hasExtraAction?: boolean;
  extraActionTitle?: string;
  extraFunc?: () => void;
  togglerFunc?: () => void;
  gridView?: boolean;
  dependencies?: any;
  isLoading:boolean;
  exportButton?: boolean;
  filterByStatus?: 'status' | 'visibility';
  exclusionList?: string[] | undefined;
};

const DataTable = (props: Props) => {
  const {
    onCreate = () => {
    }, extraFunc = () => {}, config: { columns = [], actions = [], pageSizes: defaultPageSizes = [10, 20, 30, 50, 100], hideControls = false, hideOverheadControls = false, hideButton = false, hasExtraAction = false }, data = [], title = '', ctaTitle = 'N/A',
    extraActionTitle = 'N/A', fetchFunction, paging, baseSearch = {}, fetchParams = {}, isTable = true, lineItemTemplate, togglerFunc = undefined, dependencies, gridView = true, exportButton = true, exclusionList = [], filterByStatus = false } = props;

  const history = useHistory();
  const location = useLocation();
  const params = qs.parse(location.search, { ignoreQueryPrefix: true });

  const dispatch = useDispatch();
  const [pages, setPages] = useState([1, 2, 3, 4]);

  const [currentPage, setCurrentPage] = useState<number>(
    Number(paging?.skip || params.currentPage) || 1,
  );
  const [searchWord, setSearchWord] = useState(params.searchWord as string ?? '');
  const [searchField, setSearchField] = useState(params.searchField as string ?? '');
  const [colKey, setColKey] = useState(params.colKey as string ?? '');
  const [sortDirection, setSortDirection] = useState(params.sortDirection as string ?? '');
  const [statusField, setStatusField] = useState<any>('');
  const [visibilityField, setVisibilityField] = useState<any>('');
  const [sort, setSort] = useState(false);
  const [liveOrArchive, setLiveOrArchive] = useState(true);

  const [totalPages, setTotalPages] = useState(0);
  const [total, setTotal] = useState(0);

  const pageSizes = React.useMemo(() => {
    if (!total) return defaultPageSizes; // If total is undefined/null/0, return default sizes

    const baseSizes = defaultPageSizes.filter(size => size <= total); // Keep values ≤ total
    return baseSizes.includes(total) ? baseSizes : [...baseSizes, total].sort((a, b) => a - b);
  }, [defaultPageSizes, total]);

  const [pageSize, setPageSize] = useState<number>(
    Number(paging?.limit || params.pageSize) || pageSizes[0],
  );

  useEffect(() => {
    // @ts-ignore
    const newTotalPages = Math.ceil(props.paging?.total / pageSize);
    // @ts-ignore
    setTotal(paging?.total);
    setTotalPages(newTotalPages);
    const maxPagesToShow = 4;
    const startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
    const endPage = Math.min(startPage + maxPagesToShow - 1, newTotalPages);
    const newPages = Array.from({ length: endPage - startPage + 1 }, (_, i) => i + startPage);
    setPages(newPages);
  }, [paging, pageSize, currentPage]);

  const updateQueryParams = () => {
    const currentParams = qs.parse(location.search, { ignoreQueryPrefix: true });
    const newParams: any = {
      pageSize,
      currentPage,
      colKey,
      sortDirection,
      searchWord,
      searchField,
    };
    const currentHash = window.location.hash;
    const updatedParams = { ...currentParams, ...newParams };
    const queryString = qs.stringify(updatedParams);
    history.replace({ search: `?${queryString}`, hash: currentHash });
  };

  useEffect(() => {
    if (pageSize || currentPage || searchWord || searchField || colKey || sortDirection) updateQueryParams();
  }, [pageSize, currentPage, searchWord, searchField, colKey, sortDirection]);

  // Load filters from URL on component load
  useEffect(() => {
    if (params.pageSize && !isNaN(Number(params.pageSize))) setPageSize(Number(params.pageSize));
    if (params.currentPage && !isNaN(Number(params.currentPage))) setCurrentPage(Number(params.currentPage));
    if (params.searchWord) setSearchWord(params.searchWord as string);
    if (params.searchField) setSearchField(params.searchField as string);
    if (params.colKey) setColKey(params.colKey as string);
    if (params.sortDirection) setSortDirection(params.sortDirection as string);
  }, [location.search]);

  useEffect(() => {
    if (fetchFunction && !props.isLoading){
      const dynamicFetchParams = {
        ...fetchParams,
        ...(liveOrArchive ? {} : { getArchived: true }), // Add getArchive only if liveOrArchive is false
      };
      let updatedBaseSearch: any = '';

      if (statusField !== '') {
        updatedBaseSearch = {
          ...baseSearch,
          ...(baseSearch?.status ? {} : { status: statusField }),
        };
      } else {
        updatedBaseSearch = { ...baseSearch };
      }


      if (visibilityField !== '') {
        updatedBaseSearch = {
          ...baseSearch,
          ...(baseSearch?.visibility ? {} : { visibility: visibilityField }),
        };
      } else {
        updatedBaseSearch = { ...updatedBaseSearch };
      }
      dispatch(fetchFunction({
        searchField: searchField,
        searchWord: searchWord,
        baseSearch: updatedBaseSearch,
        fetchParams: dynamicFetchParams,
        colKey: colKey,
        sortDirection: sortDirection,
        paging: pageSize.toString(),
        offset: currentPage,
      }));
    }
  }, [filterByStatus, pageSize, currentPage, searchWord, searchField, statusField, visibilityField, colKey, liveOrArchive, sortDirection, dispatch, ...(Array.isArray(dependencies) ? dependencies : [dependencies])]);


  const renderIcon = (icon: any) => {
    switch (icon) {
      case 'edit': {/* @ts-ignore */
      }
        return <Pencil/>;
      case 'convert': {/* @ts-ignore */
      }
        return <img src={assetGallery.convertImg} alt="convert"/>;
      case 'eye': {/* @ts-ignore */
      }
        return <Eye/>;
      case 'bin': {/* @ts-ignore */
      }
        return <Trash/>;
      case 'unarchive': {/* @ts-ignore */
      }
        return <FileEarmarkPlus/>;
      case 'add': {/* @ts-ignore */
      }
        return <FolderPlus/>;
      default:
        return '';
    }
  };

  const colClassName = (col: Column) => {
    let finalClassName = 'd-lg-table-cell';
    if (col.mobileFriendly === false) {
      finalClassName = `${finalClassName} d-none d-sm-none d-md-table-cell`;
    }
    if (col.tabletFriendly === false) {
      finalClassName = `${finalClassName} d-md-none`;
    }
    return finalClassName;
  };

  const getIcon = (status: string) => {
    switch (status) {
      case 'Pending':
        return <img src={assetGallery.pending} alt="Pending" />;
      case 'Accepted':
        return <img src={assetGallery.accepted} alt="Accepted" />;
      case 'Rejected':
        return <img src={assetGallery.rejected} alt="Rejected" />;
      default:
        return null;
    }
  };


  // Helper function to get nested values using dot notation
  const getNestedValue = (obj: any, path: string) => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  };

  const getValue = (row: any, col: Column) => {
    // Check if the column has an icon override
    if (col.icon) {
      return col.icon;
    }

    // Check if the column key corresponds to verification/agreement/transaction/onboarded
    switch (col.key) {
      case 'verification':
        return getIcon(row.verification);
      case 'transaction':
        return getIcon(row.transaction);
      case 'agreement':
        return getIcon(row.agreement);
      case 'onboarded':
        return getIcon(row.onboarded);
      default:
        // Use default format if no specific logic for rendering icons is provided
        return col?.format ? col.format(getNestedValue(row, col.key), row) : getNestedValue(row, col.key);
    }
  };

  //Short functionalities
  const onShort = (obj:any) =>{
    if (obj !== colKey) {
      setSort(false);
      setColKey(obj);
      setSortDirection('desc');
    } else {
      setSort(!sort);
      setColKey(obj);
      if (!sort){
        setSortDirection('asc');
      } else {setSortDirection('desc');}
    }
  };

  // Change page functionalities
  const ChangePagination = (obj:any) =>{
    setPageSize(obj.value);
    setCurrentPage(1);
  };
  const onChangePage = (p: number, e: any) =>{
    e.preventDefault();
    setCurrentPage(p);
    setPages(range(Math.max(p - 2, 1), Math.min(p + 2, totalPages) + 1));
  };

  const goToFirstPage = (e: any) => {
    onChangePage(1, e);
  };

  // Function to go to the last page
  const goToLastPage = (e: any) => {
    onChangePage(totalPages, e);
  };

  const handleExtraFunc = () => {
    setCurrentPage(1);
    extraFunc();
  };


  //Search functionalities
  let field = columns?.map(ff => {
    return {
      value: ff.key,
      label: ff.label,
    };
  });


  const updatedData = data.map((item) => {
    const requireQRCode = 'entryLocation' in item;

    const itemWithQRCode = {
      ...item,
      ...(requireQRCode ? { qrCode: generateQRCodeValue(item) } : {}),
    };

    const filteredItem = Object.fromEntries(
      Object.entries(itemWithQRCode).filter(([key]) => !exclusionList.includes(key)),
    );

    const { entryFieldData = null, ...otherFields } = filteredItem;

    return {
      ...otherFields,
      ...(entryFieldData !== null ? { entryFieldData } : {}),
    };
  });

  let fieldArray = columns?.map(ff => ff.key);
  const fieldOptions = [{ value: fieldArray, label: 'All' }, ...field].sort((a, b) => a.label > b.label ? 1 : -1);

  const onSearch = (searchTerm: string, filterTerm: string, sortTerm: string, orderBy: boolean, statusTerm: string, visibilityTerm: string) => {
    setSearchWord(searchTerm);
    setSearchField(filterTerm);
    setColKey(sortTerm);
    setStatusField(statusTerm);
    setVisibilityField(visibilityTerm);
    setSortDirection(orderBy ? 'asc' : 'desc');
    setCurrentPage(1);
  };

  const renderTable = () =>
  <>
  { props.isLoading ? <Loading/> :
      <table className="table table-striped">
    <thead className='thead-dark'>
    <tr>
      {columns.map((h) => (
          <th
              key={h.key}
              scope="col"
              className={colClassName(h)}
          >
            <div className={`column-container ${!sort &&  colKey === h.key ? 'th--active' : ''}`} >
              <div className='table--column-title'>
                {h.label}
              </div>
              <div className='column-image' onClick={()=>onShort(h.key)}>
                {(!sort || sort) && colKey !== h.key  && <MdHorizontalRule size={10}/>}
                {!sort &&  colKey === h.key && <IoMdArrowDropdown/>}
                {sort && colKey === h.key && <IoMdArrowDropup/>}
              </div>
            </div>
          </th>
      ))}
      {(!hideControls && actions.length > 0) && <th></th>}
    </tr>
    </thead>


    <tbody>
    {data?.map((row) => (
        <tr key={`${row.id}-row`} className="">
          {columns.map((col) => (
              <td valign="middle" key={`${row.id}-${col.key}`}
                  className={`d-lg-table-cell ${colClassName(col)}`}>
                {getValue(row, col)}
              </td>
          ))}
          <td valign="middle">
            {!hideControls && <div className="data__table__actions">
              {actions.map((action) => (
                (action.isVisible ? action.isVisible(row)
                  : !(liveOrArchive && action.label === 'UnArchive' || !liveOrArchive && action.label === 'Archive') ) && (
                      <div
                          className="data__table__actions__action"
                          key={action.icon}
                          onClick={() => action.onClick?.(row)}
                      >
                        {renderIcon(action.icon)}
                      </div>
                )
              ))}
            </div>}
          </td>
        </tr>
    ))}
    </tbody>
  </table>}
  </>  ;

  const renderOther = () => <div  className="row justify-content-start">
    {props.isLoading ? <Loading/> : data?.map((row) => lineItemTemplate && lineItemTemplate(row))}
  </div>;

  return (
      <>
        <div className="data__table">
          {!hideOverheadControls && <div className="row align-items-center">
            {(!hideOverheadControls && title) && <div className="col-md-5">
              <h4 className='blue-text'>{title}</h4>
            </div>}
            <div className="row align-items-center justify-content-between">
            {!hideOverheadControls && <div className="col-9">
              {/* @ts-ignore */}
              <SearchBar fields={fieldOptions} defaultSearch={searchWord} onSearch={onSearch} placeholder={'Search'} defaultFilterField={fieldArray} preFilter={searchField} preSort={colKey} preOrder={sortDirection} filterByStatus={filterByStatus == 'status' ? !baseSearch?.status : false } filterByVisibility={filterByStatus == 'visibility' ? !baseSearch?.visibility : false } />            </div>}
            {hasExtraAction && <div className="col-md-2"><Button title={extraActionTitle} onClick={handleExtraFunc} /> </div>}
            {!hideOverheadControls && !hideButton && <div className="col-md-2">
              <Button title={ctaTitle} onClick={onCreate}/>
            </div>}
            <div className="col-3 mt-md-0 mt-2 justify-self-end">
              <div className="d-flex flex-row flex-wrap gap-3">
              { togglerFunc && !props.isLoading &&
                  <ButtonToggler grid={gridView} title1={'Grid View'} title2={'Map View'}
                               img1={gridView ? assetGallery.gridViewActive : assetGallery.gridView}
                               img2={gridView ? assetGallery.mapView : assetGallery.mapViewActive}
                               onClick={togglerFunc}/>
              }
              {  exportButton &&
                  <ImageButton bold disabled = {!(updatedData && updatedData.length > 0)} label={'Download'} iconSrc={assetGallery.downloadImg} onClick={()=> exportToCSV(updatedData)}/>
             }
                {!hideControls && actions && actions.length > 0 && actions.some(action => action.label === 'UnArchive') && <ButtonToggler
                    grid={liveOrArchive}
                    title1={'Live'}
                    title2={'Archived'}
                    img1={assetGallery.liveEntries}
                    img2={assetGallery.archivedEntries}
                    bold
                    onClick={() => setLiveOrArchive(!liveOrArchive)}
                />}
              </div>
            </div>
            </div>

          </div>}
          <div className={`data__table__top ${isTable ? 'other' : ''}`}>
            {isTable ? renderTable() : renderOther()}
          </div>

          { data.length > 0 ?
            ( !props.isLoading && <>
                <div className="d-flex flex-column flex-md-row flex-lg-row justify-content-center justify-content-between paging__footer">
                  <div className="d-sm-flex justify-content-center align-items-center flex-column table__footer__left">
                    <div className="d-flex flex-row justify-content-center align-items-center">
                      <span className="paging__color-black px-2">Showing:</span>
                      <DropDown
                          placeholder={`${(pageSize > total) ? total : pageSize}`}
                          value={(pageSize > total) ? total : pageSize}
                          radius={true}
                          items={pageSizes.map(s => ({ value: s, label: `${s}` }))} onSelect={ChangePagination} type='default'/>
                      <span className="paging__color-black px-2">out of {total}</span>
                    </div>
                  </div>


                  <div className="paging d-flex justify-content-center align-items-center flex-column table__footer__right">
                    <nav aria-label="...">
                      <ul className="pagination align-items-center p-0 m-0">
                        <li className="paging__page-item page-item">
                          <img src={AssetGallery.arrowGreyImg} alt="Icon Previous"
                               className={`paging__arrow ${currentPage === 1 ? 'd-none' : ''} paging__arrow--left`}
                               onClick={(e) => onChangePage(currentPage - 1, e)}/>
                          <button className='paging__page-items d-none d-lg-block d-xl-block d-xxl-block'
                                  onClick={(e) => goToFirstPage(e)}>
                            First
                          </button>
                          <button className='paging__page-items d-block mx-2 d-lg-none d-xl-none d-xxl-none'
                                  onClick={(e) => goToFirstPage(e)}>
                            <FaAngleDoubleLeft/>
                          </button>
                        </li>
                        {pages.map(page =>
                            <li key={page} className={currentPage === page ? 'paging__li-item-active' : ''}>
                              <button className={currentPage === page ? 'paging__page-items active' : 'paging__page-items'} onClick={(e) => onChangePage(page, e)}>
                                {page}
                              </button>
                            </li>,
                        )}
                        <li className="paging__page-item page-item">
                          <button className={`paging__page-items d-none  ${currentPage === totalPages ? '' : 'd-md-block d-lg-block'}`}
                                  onClick={(e) => goToLastPage(e)}>
                            ..{totalPages}
                          </button>
                          <button className={`paging__page-items d-block mx-2 d-md-none d-lg-none ${currentPage === totalPages ? 'd-none' : ''}`}
                                  onClick={(e) => goToLastPage(e)}>
                            ..{totalPages}
                          </button>
                          <img src={AssetGallery.arrowGreyImg} alt="Icon Next"
                               className={`paging__arrow ${currentPage === totalPages ? 'd-none' : ''}`}
                               onClick={(e) => onChangePage(currentPage + 1, e)}/>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
            </>) : (!(props.isLoading) && <div className='paging__no-data'>No Data Found</div>)
          }
        </div>
      </>
  );
};

export default DataTable;
