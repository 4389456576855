import React, { useState } from 'react';
import View from '../../../components/View';
import { useSelector } from 'react-redux';
import { getEntriesHistory, isFetchingEntriesHistory } from '../../../selectors/EntryHistory';
import { activateEntryHistory, fetchEntriesHistory, storeEntryHistory } from '../../../actions/EntryHistory';

import DataTable from '../../../components/DataTable';
import { EntryHistory } from '../../../types/EntryHistory';
import Modal from '../../../components/Modal';

const EntryHistoryDataTable = () => {

  const entriesHistory = useSelector(getEntriesHistory);
  const isLoading = useSelector(isFetchingEntriesHistory);
  const [toggle, setToggle] = useState(false);
  const [unArchiveToggle, setUnArchiveToggle] = useState(false);
  const [entryHistory, setActiveEntryHistory] = useState<EntryHistory | null>();
  const [refetch, setRefetch] = useState(false);

  const onDismiss = () => {
    setToggle(false);
  };

  const onArchive = (row: EntryHistory) => {
    setActiveEntryHistory(row);
    setToggle(true);
  };

  const onUnArchive = (row: EntryHistory) => {
    setActiveEntryHistory(row);
    setUnArchiveToggle(true);
  };

  const onConfirmArchive = async () => {
    if (entryHistory?.id) {
      if (unArchiveToggle){
        await activateEntryHistory(entryHistory?.id);
      } else {
        await storeEntryHistory(entryHistory?.id);
      }
      setRefetch(!refetch);
    }
    setActiveEntryHistory(null);
    setToggle(false);
    setUnArchiveToggle(false);
  };

  const config = {
    columns: [
      { key: 'groupTitle', label: 'Group', mobileFriendly: true },
      { key: 'clientTitle', label: 'Client', mobileFriendly: true },
      { key: 'formTitle', label: 'Form', mobileFriendly: true },
      { key: 'entryTitle', label: 'Entry', mobileFriendly: true },
      { key: 'assetTitle', label: 'Asset', mobileFriendly: true },
      { key: 'title', label: 'Title', mobileFriendly: true },
      { key: 'description', label: 'Description', mobileFriendly: false },
      { key: 'notes', label: 'Notes', mobileFriendly: true },
      { key: 'created', label: 'Created At', mobileFriendly: false },
      { key: 'createdBy', label: 'Created By', mobileFriendly: false },
      { key: 'updated', label: 'Updated At', mobileFriendly: false },
    ],
    hideButton: true,
    actions: [
      { icon: 'bin', label: 'Archive', onClick: onArchive },
      { icon: 'unarchive', label: 'UnArchive', onClick: onUnArchive },
    ],
  };

  return <View title="EntriesHistory">
        <DataTable fetchFunction={fetchEntriesHistory} isLoading={isLoading} data={entriesHistory?.data || []}
                   paging={entriesHistory?.paging} config={config} dependencies={refetch}/>
    <Modal show={toggle || unArchiveToggle} title={unArchiveToggle ? 'Unarchive entry history' : 'Archive entry history'} cta={unArchiveToggle ? 'Unarchive' : 'Archive'} onCta={onConfirmArchive} onHide={onDismiss}>
      {unArchiveToggle ? 'Are you sure you want to unarchive this entry history?' : 'Are you sure you want to archive this entry history?'}
    </Modal>
    </View>;
};

export default EntryHistoryDataTable;
