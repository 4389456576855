import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import View from '../../components/View';
import './AuctionEntry.scss';
import {
  ensurePositiveInteger,
  ensurePositiveIntegerOrZero,
  formatTime,
  formatUrl, getActiveClient,
  getUserToken,
} from '../../helpers/functions';
import { fetchingAuctionsFailed, getActiveAuction, isFetchingAuctions } from '../../selectors/Auction';
import { getActiveEntry, isFetchingEntries } from '../../selectors/Entry';
import { fetchActiveEntry } from '../../actions/Entry';
import c, {
  currencyMappings,
  InvoiceType, TERMS_CONDITIONS_AUCTION_FILE,
  TransactionSourceType,
  TransactionStatus,
} from '../../helpers/constants';
import Button from '../../components/Button';
import { fetchActiveAuctionEntry } from '../../actions/AuctionEntry';
import { getActiveAuctionEntry, isFetchingAuctionEntries } from '../../selectors/AuctionEntry';
import { fetchEntryFields } from '../../actions/EntryField';
import { getEntryFields } from '../../selectors/EntryField';
import CarouselPreview from '../../components/CarouselPreview/CarouselPreview';
import { getLoggedInUser } from '../../selectors/Auth';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { fetchAuctionBids, placeAuctionBid } from '../../actions/AuctionBid';
import { getAuctionBids, isFetchingAuctionBids } from '../../selectors/AuctionBid';
import { utcToZonedTime } from 'date-fns-tz';
import { format } from 'date-fns';
import LandingPageView from '../../components/LandingPageView';
import { fetchActiveAuction } from '../../actions/Auction';
import EMEModal from '../../components/Modal';
import { postOPPTransaction } from '../../api/Subscription';
import { TransactionResponse } from '../Subscription/Subscription';
import { clearTransactionState, fetchActiveTransaction, fetchTransactions } from '../../actions/Transaction';
import { getActiveTransaction, getTransactions, isFetchingTransactions } from '../../selectors/Transaction';
import { DataTableParamsModel } from '../../types/Common';
import Receipt from '../Subscription/Receipt';

function AuctionViewRenderer() {
  const dispatch = useDispatch();
  const history = useHistory();
  const entry = useSelector(getActiveEntry);
  const auctionEntry = useSelector(getActiveAuctionEntry);
  const auction = useSelector(getActiveAuction);
  const auctionBids = useSelector(getAuctionBids);
  const carouselFields = useSelector(getEntryFields);
  const activeTransaction = useSelector(getActiveTransaction);
  const user = useSelector(getLoggedInUser);
  const allTransactions = useSelector(getTransactions);
  const isLoadingAuctions = useSelector(isFetchingAuctions);
  const isLoadingAuctionEntries = useSelector(isFetchingAuctionEntries);
  const isLoadingAuctionBids = useSelector(isFetchingAuctionBids);
  const isLoadingEntries = useSelector(isFetchingEntries);
  const isLoadingTransactions = useSelector(isFetchingTransactions);
  const fetchFailed = useSelector(fetchingAuctionsFailed);
  const client = getActiveClient();

  let matchUpdate = useRouteMatch(c.APP_ROUTES.VIEW_AUCTION_ENTRY);
  // @ts-ignore
  const auctionEntryId = matchUpdate?.params?.id;
  const isLoading = isLoadingTransactions || isLoadingAuctionBids || isLoadingAuctionEntries || isLoadingEntries || isLoadingAuctions || auctionEntryId !== auctionEntry?.id;

  const [message, setMessage] = useState('');
  const [time, setTime] = useState('0');
  const [receivedMessages, setReceivedMessages] = useState([{ type:'', content:'' }]);
  const [currentBid, setCurrentBid] = useState(Number(0));
  const [minimumBid, setMinimumBid] = useState(0);
  const [winning, setWinning] = useState(false);
  const [endOfAuction, setEndOfAuction] = useState(false);
  const [bidFlag, setBidFlag] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalInvoice, setShowModalInvoice] = useState(false);
  const [transactionData, setTransactionData] = useState<TransactionResponse | null>(null);
  const [winningUserId, setWinningUserId] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingButtonOuter, setLoadingButtonOuter] = useState(false);
  const [disablePayments, setDisablePayments] = useState(false);

  useEffect(() => {
    if (allTransactions && allTransactions.data.length > 0 && allTransactions.data[0].source === auctionEntryId) {
      dispatch(fetchActiveTransaction(allTransactions?.data[0].id));
    } else {
      dispatch(clearTransactionState());
    }
  }, [dispatch, allTransactions]);

  const allowZero = (() => {
    if (currentBid > 0){
      return false;
    }
    return auctionEntry?.startingBid == '0';
  })();


  const primaryImage = `${c.API_ENDPOINTS.ASSET_FILE}/${entry?.assetId}`;
  let assets = carouselFields?.data?.map(a => a.value.split(',').map(u => `${c.API_ENDPOINTS.ASSET_FILE}/${u}`)).flat() ?? [];

  if (primaryImage) {
    if (assets && assets.length > 0) {
      assets.unshift(primaryImage);
    } else {
      assets = [primaryImage];
    }
  }

  useEffect(() => {
    dispatch(fetchActiveAuctionEntry(auctionEntryId));
  }, [dispatch, auctionEntryId]);

  useEffect(() => {
    if (auctionEntry?.auctionId) {
      dispatch(fetchActiveAuction(auctionEntry?.auctionId));
    }
  }, [dispatch, auctionEntry?.auctionId]);

  useEffect(() => {
    if (auctionEntry?.entryId && auctionEntryId == auctionEntry?.id) {
      dispatch(fetchActiveEntry(auctionEntry.entryId));
      dispatch(fetchEntryFields({
        searchField: 'entryId',
        searchWord: auctionEntry.entryId,
        baseSearch: { 'fieldType': 'carousel' },
      }));
      if (user) {
        dispatch(fetchAuctionBids({
          searchField: 'auctionId',
          searchWord: auctionEntry.auctionId,
          baseSearch: { 'entryId': auctionEntry.entryId },
          colKey: 'amount',
          sortDirection: 'desc',
        }));
      }
    }
  }, [auctionEntry, auctionEntryId]);

  useEffect(() => {
    if (auctionEntry) {
      if (auctionEntry.winnerUserId === user?.id) {
        setWinning(true);
        setWinningUserId(user?.id);
      } else {
        setWinning(false);
        setWinningUserId('');
      }
    } else {
      setWinning(false);
      setWinningUserId('');
    }
  }, [auctionEntry, user]);

  useEffect(() => {
    if (getUserToken() && auctionBids?.data[0] && auctionEntry?.bidIncrement) {
      setCurrentBid(Number(auctionBids?.data[0].amount));
      setMinimumBid(Number(auctionBids?.data[0].amount) + Number(auctionEntry?.bidIncrement));
    } else {
      if (Number(auctionEntry?.startingBid) === 0 && auctionBids?.data.length === 0){
        setMinimumBid(0);
      } else if (Number(auctionEntry?.startingBid) !== 0 && auctionBids?.data.length === 0){
        setMinimumBid(Number(auctionEntry?.startingBid));
      } else {
        setMinimumBid(
          (Number(auctionEntry?.startingBid) + Number(auctionEntry?.bidIncrement) ?? 0),
        );
      }
      setCurrentBid(0);
    }
  }, [auctionBids, auctionEntry, auctionEntryId]);


  useEffect(() => {
    if (user && auctionEntry){
    // Enable pusher logging - don't include this in production
      (window as any).Pusher.logToConsole = true;

      const pusher = new (window as any).Pusher('c29de59b35bba4e24ef0', {
        cluster: 'eu',
      });
      const channel = pusher.subscribe('eme-test-app-development');
      channel.bind(auctionEntryId, (data: any) => {

        const parsedData = JSON.parse(data.message);

        if (parsedData.type === 'broadcast' && auctionEntry?.bidIncrement && parsedData.userId !== user?.id){
          setCurrentBid(parsedData.amount);
          setMinimumBid(Number(parsedData.amount) + Number(auctionEntry?.bidIncrement));
          setWinning(false);
          setBidFlag(true);
          const receivedMessage = { content :parsedData.message, type: parsedData.type ? parsedData.type : 'error' };
          setReceivedMessages((prevMessages) => {
            return [receivedMessage, ...prevMessages.slice(0, 2)];
          });
        }

      });

      // Clean up Pusher connection when component unmounts
      return () => {
        pusher.disconnect();
      };
    }
  }, [user, auctionEntry]); // Empty dependency array ensures useEffect runs once after initial render


  const sendMessage = async () => {
    if (auctionEntry && user){
      try {
        const response = await dispatch(placeAuctionBid(auctionEntry.id, user.id, message));

        // @ts-ignore
        const receivedMessage = { content :response.message, type: response.type ? response.type : 'error' };
        setReceivedMessages((prevMessages) => {
          return [receivedMessage, ...prevMessages.slice(0, 2)];
        });
        // @ts-ignore
        if (receivedMessage.type === 'success' && response.data.amount !== null && auctionEntry?.bidIncrement){
          // @ts-ignore
          setCurrentBid(response.data.amount);
          // @ts-ignore
          setMinimumBid(Number(response.data.amount) + Number(auctionEntry?.bidIncrement));
          setWinning(true);
          setBidFlag(true);
        }
      } catch (error) {
        console.error('Error sending message:', error);
      }
    }
  };


  useEffect(() => {
    const initialDate = utcToZonedTime(new Date().toISOString(), 'UTC');
    const initialCTime = initialDate.getTime();
    if (auctionEntry && auctionEntry?.endDateTime){
      if (new Date(auctionEntry.endDateTime).getTime() <= initialCTime){
        setEndOfAuction(true);
      } else {
        setEndOfAuction(false);
      }
    }
    const intervalId = setInterval(() => {
      if (auctionEntry && auctionEntry.endDateTime) {
        const cDate = utcToZonedTime(new Date().toISOString(), 'UTC');
        const cTime = cDate.getTime();
        setTime(formatTime(new Date(auctionEntry.endDateTime).getTime() - cTime));
        if (new Date(auctionEntry.endDateTime).getTime() <= cTime){
          setEndOfAuction(true);
        }
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [auctionEntry]);

  const handleBack = () => {
    history.push(formatUrl(c.APP_ROUTES.VIEW_AUCTION, auctionEntry?.auctionId));
  };

  const toEntry = () => {
    if (auctionEntry){
      const url = `${formatUrl(c.APP_ROUTES.ENTRY_RENDERER_PUBLIC, auctionEntry?.entryId)}?source=auction`;
      if (getUserToken()){
        window.open(url, '_blank'); // Opens the URL in a new tab
      } else {
        history.push(formatUrl(c.APP_ROUTES.ENTRY_RENDERER_PUBLIC, auctionEntry?.entryId));
      }
    }
  };

  const toLogin = () => {
    localStorage.setItem('returnUrl', formatUrl(c.APP_ROUTES.VIEW_AUCTION_ENTRY, auctionEntryId));
    history.push(c.APP_ROUTES.USER_LOGIN);
  };

  //todo i cannot have the transactionid only in the url, if someone come in normally I have to fetch all the transaction for this user for this auction entry and decide depending on the status
  useEffect(() => {
    if (auctionEntryId && user) {
      const transactionDataTableParams: DataTableParamsModel = {
        paging: '100',
        searchField: 'source',
        searchWord: auctionEntryId ?? 'undefined', //fallback to string that will return no items
      };
      dispatch(fetchTransactions(transactionDataTableParams));
    }
  }, [dispatch, auctionEntryId]);

  useEffect(() => {
    if (!activeTransaction?.status && !allTransactions?.data?.length){
      setDisablePayments(false);
    }

    const status = (activeTransaction?.status || allTransactions?.data?.[0]?.status) as TransactionStatus;

    if (!status){
      setDisablePayments(false);
    }

    const disabledStatuses = [
      TransactionStatus.PENDING,
      TransactionStatus.PLANNED,
      TransactionStatus.RESERVED,
      TransactionStatus.COMPLETED,
    ];

    // Handle SEPA transactions separately
    if (status === TransactionStatus.PENDING && activeTransaction?.payment_method === 'sepa') {
      setDisablePayments(false);
    } else {
      setDisablePayments(disabledStatuses.includes(status));
    }
  }, [activeTransaction, allTransactions]);

  const handleModalClose = () =>{
    setShowModal(false);
    setLoadingButtonOuter(false);
  };

  const handleModalCloseInvoice = () =>{
    setShowModalInvoice(false);
  };

  const handlePayNow = async ()=>{
    if (winningUserId){
      if (activeTransaction?.payment_method === 'sepa' && activeTransaction?.status === TransactionStatus.PENDING){
        window.location.href = activeTransaction?.redirect_url;
        setLoadingButtonOuter(true);
      } else {
        setLoadingButtonOuter(true);
        const asyncResp = await postOPPTransaction('', winningUserId, entry?.title ?? 'Auction Product', currentBid, auctionEntry?.id ?? '', TransactionSourceType.AUCTION, auction?.currency ?? currencyMappings.euro);
        if (asyncResp?.success && asyncResp?.data){
          setTransactionData(asyncResp?.data);
          setShowModal(true);
        } else if (asyncResp?.success === false && asyncResp?.error){
          setShowModal(false);
          setErrorMessage(asyncResp?.error);
          setLoadingButtonOuter(false);
          setLoadingButton(false);
          setDisablePayments(true);
        }
      }
    } else {
      console.log('something went wrong');
    }
  };

  const handleProceedToBank = async () => {
    setLoadingButton(true);
    if (transactionData  && winningUserId) {
      window.location.href = transactionData?.redirect_url; // Redirect to OPP payment page
    } else {
      setErrorMessage('something went wrong');
    }
  };

  const renderMessage = () => {
    if (!activeTransaction?.status) return null;
    switch (activeTransaction.status) {
      case TransactionStatus.CREATED:
        return (
            <div className="p-4 bg-info text-black rounded-3 shadow">
              <p>Your payment has been initiated but is not yet complete. Please proceed to the payment page to finalize
                the transaction.</p>
            </div>
        );
      case TransactionStatus.PENDING:
        if (activeTransaction.payment_method === 'sepa') {
          return (
              <div className="p-4 bg-warning text-black rounded-3 shadow">
                <p>
                  Your SEPA payment is currently pending. Bank processing may take some time before it reaches us. You can view the SEPA details again by clicking the Proceed button.
                </p>
              </div>
          );
        } else {
          return (
              <div className="p-4 bg-warning text-black rounded-3 shadow">
                  <p>Your payment is currently pending. No further action can be taken at this time.
                      If payment is not received, the transaction will expire automatically.
                      You may initiate a new transaction after the current one expires</p>
              </div>
          );
        }
      case TransactionStatus.PLANNED:
      case TransactionStatus.RESERVED:
        return (
            <div className="p-4 bg-info text-black  rounded-3 shadow">
              <p>Your payment is being processed. You will receive an email when your transaction is completed.</p>
            </div>
        );
      case TransactionStatus.FAILED:
      case TransactionStatus.EXPIRED:
      case TransactionStatus.CANCELLED:
        return (
            <div className="p-4 bg-warning text-black rounded-3 shadow">
              <h4>Payment Failed</h4>
              <p>Something went wrong with your payment. Please try again. If the issue persists, contact an administrator.</p>
            </div>
        );
      case TransactionStatus.COMPLETED:
        return (
            <>
              <div className="p-4 bg-success text-white rounded-3 shadow">
                Your payment is completed.
              </div>
              <div className="col-12 mt-1"><Button onClick={()=>setShowModalInvoice(true)} title={'Show Invoice'}/></div>
            </>
        );
      default:
        return (
            <div className="p-4 bg-danger text-white rounded-3 shadow">
              <h4>An error has occurred</h4>
              <p>Error: {activeTransaction?.id}</p>
            </div>
        );
    }
  };


  const auctionContent = (
      <div className="page">
            <div className="container auction-entry__box">
                <div className="row mt-3">
                    <div className="col-3 col-md-4 col-xl-6 justify-content-start">
                        <Button title={'Back To Auction'} onClick={handleBack} />
                    </div>
                    <div className="col-9 col-md-8 col-xl-6 justify-content-end auction-entry__time align-items-center">
                        <div className='auction-entry__time__text'>
                            {endOfAuction ? winningUserId ? 'You are the winner!' : 'CONCLUDED' : 'Time Left: ' + time}
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12 col-md-5 me-4">
                        <div className="col-12 auction-entry__title mx-2">
                            {entry?.title.toUpperCase()}
                        </div>
                        <div className="col-12">
                            <CarouselPreview imageUrls={assets} imagesToShow={1}/>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 mt-3">
                        <div className="row py-2 justify-content-end">
                            <div className='d-none d-md-block col-2 col-sm-1 col-md-1'/>

                            {(!endOfAuction && getUserToken()) && (
                                <>
                                    <div className="col-5 col-md-3 auction-entry__current-bid justify-content-end align-items-end mx-1">
                                        <div className='auction-entry__label--bold'>Current Bid</div>
                                        {(currentBid === 0 && minimumBid === 0 || (auctionBids?.data.length === 0 && !bidFlag))
                                          ? 'Be the first to place a bid'
                                          : `${currentBid} ${auction?.currency}`}
                                    </div>
                                    <div className="col-5 col-md-3 auction-entry__current-bid justify-content-end align-items-end">
                                        {winning ? 'You are currently winning!' : 'Bid in order to take the lead'}
                                    </div>
                                </>
                            )}
                        </div>
                        {!endOfAuction ? (
                            <>
                              <div className="col-12 auction-entry__minimum-bid">
                                Live since: {auctionEntry?.startDateTime
                                ? `${format(new Date(auctionEntry.startDateTime), 'EEEE do MMMM yyyy \'at\' HH:mm a')} UTC`
                                : 'N/A'}
                              </div>
                              {getUserToken() ? <div className="col-12 auction-entry__minimum-bid">Minimum allowed
                                    bid: {isLoadingAuctionBids ? 'Loading' : minimumBid} {auction?.currency}</div>
                                : <div className="col-12 auction-entry__minimum-bid">Bidding starts at {isLoadingAuctionBids ? 'Loading' : minimumBid} {auction?.currency}.</div>}
                                {getUserToken() ? <>
                                    <div className="col-12 py-3 justify-content-center">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={`Insert an amount in ${auction?.currency}`}
                                            value={message}
                                            onChange={(e) => {
                                              const value = e.target.value;
                                              if (allowZero) {
                                                setMessage(ensurePositiveIntegerOrZero(value));
                                              } else {
                                                setMessage(ensurePositiveInteger(value));
                                              }
                                            }}
                                        />
                                    </div>
                                    <div className="col-12 justify-content-start">
                                        <Button onClick={sendMessage} disabled={!message} title={'Place Bid'}/>
                                    </div>
                                </> : <Button onClick={toLogin} title="Log in to place your bid!"></Button>}
                                <div className="auction-entry__messages">
                                    {receivedMessages.map((msg, index) => (
                                        <div className={`col-12 p-1 my-1 auction-entry__message auction-entry__message-${msg.type}`}
                                             key={index}>{msg.content}</div>
                                    ))}
                                </div>
                            </>
                        ) : (
                            <div className="auction-entry__current-bid auction-entry__current-bid--grey">
                              {currentBid !== 0
                                ? `This auction started on ${auctionEntry?.startDateTime} and concluded on ${auctionEntry?.endDateTime}. The winning bid is: ${currentBid} ${auction?.currency}`
                                : (Number(auctionEntry?.startingBid) === 0 && auctionBids && auctionBids?.data.length > 0)
                                  ? `This auction concluded on ${auctionEntry?.endDateTime}. The item was claimed for free.`
                                  : `This auction has concluded on ${auctionEntry?.endDateTime}.`}
                            </div>
                        )}
                      <div className="col-12 mt-3">
                        <div className="col-12 auction-entry__title-description">
                         Item Description
                        </div>
                        <div className="col-12 mt-1">
                          {entry?.description}
                        </div>
                        <div className="col-12 mt-1">
                          <Button title={'Item Details'} disabled={isLoading} onClick={toEntry}/>
                        </div>
                        {(!auction?.enableAuctionPayments && endOfAuction && winningUserId) &&
                            <div className="col-12 p-3 bg-info text-black rounded-3 shadow">In order to proceed with a payment your client needs to enable auction payments.</div>}
                        {(endOfAuction && winningUserId && !!auction?.enableAuctionPayments) &&
                            <div>
                              <div className="col-12 auction-entry__title-description">
                          Payment
                          </div>
                        {currentBid != 0 ? <div className="col-12 mt-2">
                        {activeTransaction?.status !== TransactionStatus.COMPLETED && <Button title={'Pay Now'}
                        isLoading={loadingButtonOuter}
                                       disabled={loadingButtonOuter || isLoading || disablePayments}
                                       onClick={handlePayNow}/>}
                              {!errorMessage ? renderMessage() : <div> {errorMessage} </div>}
                            </div> :
                                <div className="col-12 p-3 bg-info text-black rounded-3 shadow">You will receive an email
                                  with additional information regarding acquiring your item.</div>}
                          </div>
                        }
                      </div>
                    </div>
                </div>
            </div>
          {fetchFailed && <p>Failed to fetch auction.</p>}
        </div>
  );

  // Conditionally render either View or LandingPageView based on login status
  if (!getUserToken()) {
    return <LandingPageView isLoading={isLoading}><div className={'pt-4'}>{auctionContent}</div></LandingPageView>;
  }

  return <View isLoading={isLoading}>{auctionContent}
    <EMEModal
        show={showModalInvoice}
        onHide={handleModalCloseInvoice}
    >
      <Receipt
          clientData={client}
          activeTransaction={activeTransaction}
          itemName={entry?.title ?? 'Auction Product'}
          itemDescription={entry?.description ?? 'Auction Product'}
          invoiceType={InvoiceType.AUCTION}
      />
    </EMEModal>
    <EMEModal
        show={showModal}
        onHide={handleModalClose}
        title={
          (transactionData && transactionData?.redirect_url)
            ? loadingButton
              ? 'Redirecting, please wait'
              : 'You will be redirected to a safe payments environment'
            : 'Something Went Wrong'
        }
    >
      {(transactionData && transactionData?.redirect_url)  ?
          <>
            <div className="row">
              <div className="col">
                By clicking on “Proceed”:<br/>
                <ul>
                  <li>
                    a sales agreement between Excess Materials Exchange B.V. (<b>Seller</b>)
                    and {user?.companyName ?? client.title} (<b>Buyer</b>) is concluded, to which the <a
                      href={`${c.APP_ROUTES.CORE}/${TERMS_CONDITIONS_AUCTION_FILE}`} target="_blank"
                      rel="noopener noreferrer"> General terms and conditions for sales of goods </a>
                    (the <b>General Terms</b>) apply. These General Terms, which inter alia include a
                    choice of forum for the competent court in Amsterdam, the Netherlands and a choice of law for the
                    laws of the Netherlands (without the applicability of the Vienna Sales Convention), can be viewed and saved by clicking on the hyperlink above.
                  </li>
                  <li>
                    If Excess Materials Exchange B.V. or one of its Affiliates (collectively referred to as: EME) is not the Seller, Buyer shall owe the Transaction Fee to EME under the applicable Services Agreement between Buyer and EME.
                  </li>
                  <li>
                    Buyer will be redirected to the payment environment for payment of the Price and the Transaction Fee (if applicable).
                  </li>
                </ul>
              </div>
            </div>
            <div className="row justify-content-between">
              <div className="col-auto">
                <Button disabled={loadingButton} onClick={handleModalClose} title="Cancel"></Button>
              </div>
              <div className="col-auto">
                <Button disabled={loadingButton} onClick={handleProceedToBank} title="Proceed"></Button>
              </div>
            </div>
          </> :
          <div className="row">
            <div className="col-auto">
              {errorMessage ?
                errorMessage :
                'Refresh the page and try again, if the issue persist contact an administrator.'}
            </div>
          </div>}
    </EMEModal>
  </View>;

}

export default AuctionViewRenderer;
