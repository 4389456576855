import React, { useState } from 'react';
import View from '../../../components/View';
import { useSelector } from 'react-redux';
import { getEntryFieldsHistory, isFetchingEntryFieldsHistory } from '../../../selectors/EntryFieldHistory';
import { fetchEntryFieldsHistory } from '../../../actions/EntryFieldHistory';

import DataTable from '../../../components/DataTable';
import { activateEntryFieldHistory, storeEntryFieldHistory } from '../../../actions/EntryFieldHistory';
import Modal from '../../../components/Modal';
import { EntryFieldHistory } from '../../../types/EntryFieldHistory';


const EntryFieldHistoryDataTable = () => {

  const entryFieldsHistory = useSelector(getEntryFieldsHistory);
  const isLoading = useSelector(isFetchingEntryFieldsHistory);
  const [toggle, setToggle] = useState(false);
  const [unArchiveToggle, setUnArchiveToggle] = useState(false);
  const [entryFieldHistory, setActiveEntryFieldHistory] = useState<EntryFieldHistory | null>();
  const [refetch, setRefetch] = useState(false);

  const onDismiss = () => {
    setToggle(false);
  };

  const onArchive = (row: EntryFieldHistory) => {
    setActiveEntryFieldHistory(row);
    setToggle(true);
  };

  const onUnArchive = (row: EntryFieldHistory) => {
    setActiveEntryFieldHistory(row);
    setUnArchiveToggle(true);
  };

  const onConfirmArchive = async () => {
    if (entryFieldHistory?.id) {
      if (unArchiveToggle){
        await activateEntryFieldHistory(entryFieldHistory?.id);
      } else {
        await storeEntryFieldHistory(entryFieldHistory?.id);
      }
      setRefetch(!refetch);
    }
    setActiveEntryFieldHistory(null);
    setToggle(false);
    setUnArchiveToggle(false);
  };

  const config = {
    columns: [
      { key: 'groupTitle', label: 'Group', mobileFriendly: true },
      { key: 'clientTitle', label: 'Client', mobileFriendly: true },
      { key: 'fieldTitle', label: 'Form', mobileFriendly: true },
      { key: 'entryTitle', label: 'Entry', mobileFriendly: true },
      { key: 'value', label: 'Value', mobileFriendly: true },
      { key: 'notes', label: 'Notes', mobileFriendly: false },
      { key: 'created', label: 'Created At', mobileFriendly: false },
      { key: 'createdBy', label: 'Created By', mobileFriendly: false },
      { key: 'updated', label: 'Updated At', mobileFriendly: false },
    ],
    hideButton: true,
    actions: [
      { icon: 'bin', label: 'Archive', onClick: onArchive },
      { icon: 'unarchive', label: 'UnArchive', onClick: onUnArchive },
    ],
  };

  return <View title="EntryFieldsHistory">
        <DataTable fetchFunction={fetchEntryFieldsHistory} isLoading={isLoading} paging={entryFieldsHistory?.paging}
                   data={entryFieldsHistory?.data || []} config={config} dependencies={refetch}/>
    <Modal show={toggle || unArchiveToggle} title={unArchiveToggle ? 'Unarchive entry field history' : 'Archive entry field history'} cta={unArchiveToggle ? 'Unarchive' : 'Archive'} onCta={onConfirmArchive} onHide={onDismiss}>
      {unArchiveToggle ? 'Are you sure you want to unarchive this entry field history?' : 'Are you sure you want to archive this entry field history?'}
    </Modal>
    </View>;
};

export default EntryFieldHistoryDataTable;
